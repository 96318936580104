import React from "react";
import { Input } from "antd";

const ThemeInput = ({ value, onChange, disabled, className, placeholder,type  }) => {
  return (
    <Input
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      className={`book-ant-input ${className}`}
      value={value}
      style={{ width: "100%", minWidth: "300px", height: "40px" }}
    />
  );
};

export default ThemeInput;
