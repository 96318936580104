import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Main from "./pages/Main";
import FindDoctor from "./pages/FindDoctor";
import BookAppointment from "./pages/BookAppointment";
import Appointment from "./pages/bookedappointmentpage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="black"
        progressStyle={{ background: "#0B3D58", height: "4px" }}
      />
      <Routes>
        <Route exact path="/" element={<Main />}></Route>
        <Route
          exact
          path="/book-appointments"
          element={<BookAppointment />}
        ></Route>
        <Route exact path="/find-your-doctor" element={<FindDoctor />}></Route>
        <Route
          exact
          path="/booked-appointment-detail"
          element={<Appointment />}
        />
      </Routes>
    </Router>
  );
}

export default App;
