import React, { useEffect, useState } from "react";
import "./DoctorsCard.css";

const DoctorsCard = ({ doctorApiData, onSelect }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isAutoSelected, setIsAutoSelected] = useState(false); // New state to track auto-selection
  const amountType = doctorApiData?.amount_type;
  const doctorItems = doctorApiData?.doctoritem;

  useEffect(() => {
    console.log("doctorApiData", doctorApiData);
  }, [doctorApiData]);

  // Automatically select the item if doctorItems has only one element
  useEffect(() => {
    if (doctorItems && doctorItems.length === 1) {
      const singleItem = doctorItems[0];
      setSelectedItems([singleItem.id]);
      onSelect(singleItem, true); // Call onSelect with true to add to cart
      setIsAutoSelected(true); // Set auto-selected state to true
    }
  }, [doctorItems, onSelect]);

  if (!Array.isArray(doctorItems)) {
    return null;
  }

  const handleSelect = (item) => {
    const isSelected = selectedItems.includes(item?.id);

    setSelectedItems((prev) => {
      let newSelection;

      if (amountType === 1) {
        newSelection = isSelected ? [] : [item?.id];
      } else {
        newSelection = isSelected
          ? prev.filter((id) => id !== item?.id)
          : [...prev, item?.id];
      }

      // Reset auto-selection state if changing selection manually
      setIsAutoSelected(false);
      return newSelection;
    });

    onSelect(item, !isSelected);
  };

  return (
    <div className="doctors-cards-container">
      {doctorItems.map((item) => (
        <div key={item?.id} className="blood-test-card">
          <div className="card-header">
            <h3 className="card-title">{item?.item_name}</h3>
          </div>
          <p className="card-description">{item?.item_desc}</p>
          <div className="card-footer">
            <div className="price-container">
              <span className="currency">PKR</span>
              <span className="price">
                {item?.item_price.toLocaleString()}/-
              </span>
            </div>
            <button
              className={`select-button ${
                selectedItems.includes(item?.id) ? "selected" : ""
              }`}
              onClick={() => handleSelect(item)}
              disabled={
                isAutoSelected || // Disable button if auto-selected
                (amountType === 1 &&
                  selectedItems.length > 0 &&
                  !selectedItems.includes(item?.id))
              }
            >
              {selectedItems.includes(item?.id)
                ? "Remove Service"
                : "Select Service"}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DoctorsCard;
