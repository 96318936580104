import React from 'react';
import { Calendar } from 'react-calendar';
import './Calendar.css';

const AppointmentCalendar = ({ date, handleDateChange, tileDisabled }) => {
  return (
    <div className="calendar-outer-container">
      <div className="calendar-inner-container">
        <Calendar
          value={date}
          minDate={new Date()}
          onChange={handleDateChange}
          tileDisabled={tileDisabled}
          className="custom-calendar"
          formatShortWeekday={(locale, date) => 
            ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]
          }
          prevLabel="‹"
          nextLabel="›"
        />
      </div>
    </div>
  );
};

export default AppointmentCalendar;