import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Carousel from "../component/Carousel";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import axios from "axios";
import CONSTANT from "../constant.json";
import { Spin } from "antd";
import DoctorCardSkeleton from "../component/CardSkeleton";

function MultipleItems() {
  const [doctorData, setDoctorData] = useState([]);
  const [doctorApiData, setDoctorApiData] = useState([]);
  const [docCategories, setdocCategories] = useState([]);

  // const docCategories = ; // Add your categories here
  const [activeCategory, setActiveCategory] = useState("All"); // Initialize active category state
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${CONSTANT.url}api/Doctor/get`);
      setDoctorApiData(response.data?.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchCategoriesData = async () => {
    try {
      const response = await axios.get(`${CONSTANT.url}api/categories/get`);
      setdocCategories(response.data?.data.slice(0, 4));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategoriesData();
    fetchData();

    // Cleanup function if needed
    return () => {
      // Cleanup code here
    };
  }, []);

  useEffect(() => {
    setDoctorData(doctorApiData);
  }, [doctorApiData]);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    // Update active category when a tab is clicked
    if (category === "All") {
      setDoctorData(doctorApiData);
    } else {
      const filteredData = doctorApiData.filter((v) => {
        return v.categories.map((d) => d.name).includes(category);
      });

      setDoctorData(filteredData);
    }
  };
  return (
    <div>
      <div
        id="react-app"
        style={{
          maxWidth: "1400px",
          position: "relative",
          margin: "20px auto",
        }}
        className="doc-carousel"
      >
        <div className="filter-wrapper">
          <div
            className={`filter-tab ${
              "All" === activeCategory ? "active-filter-tab" : ""
            }`}
            onClick={() => handleCategoryClick("All")}
          >
            All
          </div>
          {docCategories.map((category, index) => (
            <div
              key={index}
              className={`filter-tab ${
                category.name === activeCategory ? "active-filter-tab" : ""
              }`}
              onClick={() => handleCategoryClick(category.name)}
            >
              {category.name}
            </div>
          ))}
          <div className="filter-view-all">
            {/* <Link to={"https://stage.eclinicasia.com/find-your-doctor/"}> */}
              <Link to={"/find-your-doctor"}>
              Full View <FaLongArrowAltRight />
            </Link>
          </div>
        </div>
        {doctorData.length ? (
          <Carousel data={doctorData} />
        ) : loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <DoctorCardSkeleton />
            <DoctorCardSkeleton />
            <DoctorCardSkeleton />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              height: "300px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            There are no doctors in this category.
          </div>
        )}
      </div>
    </div>
  );
}

export default MultipleItems;
