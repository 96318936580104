// App.js
import React, { useState, useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import OTPForm from "./OtpForm";
import axios from "axios";
import { notification, Radio, Spin, Tooltip } from "antd";
import CONSTANT from "../../constant.json";
import GoogleLogin from "../GoogleLogin";
import FacebookLogin from "../FacebookLogin";
import LinkedinLogin from "../LinkedinLogin";
const radioOptions = ["Male", "Female", "Do not prefer"];

const SignupLogin = ({ currentStatus }) => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  var popupWindowRef = null;
  var provider = null;

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    dob: "",
    gender: "Male",
    phone: "",
    address: "",
    password: "",
    c_password: "",
  });
  const [isLogin, setIsLogin] = useState(currentStatus == "1");
  const [isOtp, setIsOtp] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [termsChecked, setTermsChecked] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setisLoading] = useState(false);

  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message,
      description,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.c_password) {
      openNotificationWithIcon(
        "error",
        "Password Mismatch",
        "The passwords do not match. Please re-enter them."
      );
      return;
    }
    if (!termsChecked) {
      openNotificationWithIcon(
        "error",
        "Agree Terms & Conditions",
        "Agree Terms & Conditions to Continue"
      );
      return false;
    }
    try {
      const response = await axios.post(
        `${CONSTANT.url}api/patient/create`,
        formData
      );
      console.log("Signup response:", response.data);
      openNotificationWithIcon(
        "success",
        "Signup Successful",
        "You have successfully signed up."
      );
      setIsOtp(true);
    } catch (error) {
      console.error("Signup error:", error);
      if (error.response?.data)
        if (error.response.data?.data) {
          console.log(Object.values(error.response.data?.data));
          Object.values(error.response.data?.data).map((v) =>
            openNotificationWithIcon("error", "Signup Failed", v[0])
          );
        }
    }
  };

  const handleSocialLogin = async (prov) => {
    try {
      setisLoading(true);
      const response = await axios.get(`${CONSTANT.url}api/login/${prov}`);

      localStorage.setItem("provider", prov);
      localStorage.setItem("popref", popupWindowRef);
      // setIsLogin(false)
      window.location.href = response.data;

      // window.location.href = response;
      // Store token in localStorage or session storage
      // Redirect or navigate to the dashboard
    } catch (error) {
      console.log(error);
      // Handle error
      setisLoading(true);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${CONSTANT.url}api/login`, {
        email: formData.email,
        password: formData.password,
      });

      processLogin(response);
    } catch (error) {
      console.error("Login error:", error);
      if (error.response?.data && error.response.status != 200)
        if (error.response.data?.data) {
          console.log(Object.values(error.response.data?.data));
          Object.values(error.response.data?.data).map((v) => {
            if (v === "unverified") {
              setIsOtp(true);
            }
            return openNotificationWithIcon("error", "Login Failed", v);
          });
        }
    }
  };

  function processLogin(response) {
    try {
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("user_id", response.data.data?.user?.patient_id);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      console.log("Login response:", response.data);
      openNotificationWithIcon(
        "success",
        "Login Successful",
        "You have successfully logged in."
      );
      setisLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Login error:", error);
      console.error("Login errsdsdsdor:", response);
      if (error.response?.data && error.response.status != 200)
        if (error.response.data?.data) {
          console.log(Object.values(error.response.data?.data));
          Object.values(error.response.data?.data).map((v) => {
            if (v === "unverified") {
              setIsOtp(true);
            }
            return openNotificationWithIcon("error", "Login Failed", v);
          });
        }
    }
  }

  const handleOTPSubmit = async () => {
    try {
      const response = await axios.post(
        `${CONSTANT.url}api/patient/OtpVerify`,
        {
          email: formData.email,
          otp: otp.join(""),
        }
      );
      console.log("OTP verification response:", response.data);
      openNotificationWithIcon(
        "success",
        "OTP Verification Successful",
        "Your OTP has been successfully verified."
      );
      setIsOtp(false);
      setIsLogin(true);
    } catch (error) {
      console.error("OTP verification error:", error);
      openNotificationWithIcon(
        "error",
        "OTP Verification Failed",
        "An error occurred while verifying OTP. Please try again later."
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setisLoading(true);
      var provider = localStorage.getItem("provider");

      if (code) {
        try {
          const response = await axios.get(
            `${CONSTANT.url}api/login/` + provider + `/callback?code=` + code
          );
          processLogin(response);
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error
          setisLoading(false);
        }
      } else {
        setisLoading(false);
        console.log("Code is null or undefined");
      }
    };

    fetchData();
  }, [code]);

  return (
    <div
      className={`appointment-container ${
        isOtp ? "otp" : isLogin ? "login" : ""
      }`}
    >
      <Spin spinning={isLoading} fullscreen />
      {isOtp ? (
        <h1 className="heading-register">OTP</h1>
      ) : !isLogin ? (
        <h1 className="heading-register">Create Account</h1>
      ) : (
        <h1 className="heading-register">Login</h1>
      )}
      {isOtp ? (
        <OTPForm onSubmit={handleOTPSubmit} otp={otp} setOtp={setOtp} />
      ) : (
        <form
          onSubmit={isLogin ? handleLoginSubmit : handleSignupSubmit}
          className={`appointment-form ${isLogin ? "login" : ""}`}
        >
          {!isLogin && !isOtp && (
            <>
              <div className="appointment-form-group">
                <label style={{ fontWeight: 600 }}>Name: </label>

                <input
                  type="text"
                  id="appointment-name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Name"
                />
              </div>
              <div className="appointment-form-group">
                <label style={{ fontWeight: 600 }}>Phone: </label>

                <input
                  type="tel"
                  id="appointment-phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  placeholder="Phone"
                />
              </div>
              <div className="appointment-form-group">
                <label style={{ fontWeight: 600 }}>Date of Birth: </label>

                <input
                  type="date"
                  id="appointment-dob"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  max={new Date().toISOString().split("T")[0]}
                  required
                  placeholder="Date of Birth"
                />
              </div>
            </>
          )}
          <div className="appointment-form-group">
            <label style={{ fontWeight: 600 }}>Email: </label>

            <input
              type="email"
              id="appointment-email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="Email"
            />
          </div>
          <div className="appointment-form-group">
            <label style={{ fontWeight: 600 }}>Password: </label>

            <Tooltip title="Password must contain 8 characters">
              <input
                type="password"
                id="appointment-password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                minLength={6}
                required
                placeholder="Password"
              />
            </Tooltip>
          </div>
          {isLogin && (
            <div className="forget-pass-btn">
              <a href="https://portal.eclinicasia.com/forget-password">
                Forgot Password?
              </a>
            </div>
          )}
          {!isLogin && !isOtp && (
            <>
              <div className="appointment-form-group">
                <label style={{ fontWeight: 600 }}>Confirm Password: </label>
                <Tooltip title="Password must contain 8 characters">
                  <input
                    type="password"
                    id="appointment-c_password"
                    name="c_password"
                    value={formData.c_password}
                    onChange={handleChange}
                    minLength={8}
                    required
                    placeholder="Confirm Password"
                  />
                </Tooltip>
              </div>

              <div className="appointment-form-group">
                <label style={{ fontWeight: 600 }}>Address: </label>

                <textarea
                  type="text"
                  id="appointment-address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                  placeholder="Address"
                />
              </div>
              <div className="appointment-form-group">
                <label style={{ fontWeight: 600 }}>Gender: </label>
                <Radio.Group
                  name="gender"
                  options={radioOptions}
                  onChange={handleChange}
                  style={{ display: "flex" }}
                  value={formData.gender}
                />
              </div>
              <div
                className="appointment-form-group"
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  height: "40px",
                }}
              >
                <input
                  type="checkbox"
                  id="terms-checkbox"
                  name="terms"
                  checked={termsChecked}
                  onChange={() => setTermsChecked(!termsChecked)}
                />
                <label htmlFor="terms-checkbox" style={{ marginLeft: "8px" }}>
                  I agree to the{" "}
                  <span>
                    <a
                      target="_blank"
                      href="https://eclinicasia.com/wp-content/uploads/2024/04/TermsandConditionsEnglish.pdf"
                    >
                      Terms and Conditions
                    </a>
                  </span>
                </label>
              </div>
            </>
          )}
          <button type="submit" className="appointment-submit-btn">
            {isLogin ? "Login" : "Sign Up"}
          </button>
        </form>
      )}
      {!isOtp ? (
        <>
          <div className="login-navigate">
            {isLogin ? (
              <p>
                Don't have an account?{" "}
                <span onClick={() => setIsLogin(false)}>Sign Up</span>
              </p>
            ) : (
              <p>
                Already have an account?{" "}
                <span
                  onClick={() => {
                    setIsLogin(true);
                    setIsOtp(false);
                  }}
                >
                  Login
                </span>
              </p>
            )}
          </div>
          <div className="social-main">
            <p className="social-heading">Sign In via :</p>
            <div className="social-book-icon-main">
              <button
                className="social-book-icon facebook"
                onClick={() => handleSocialLogin("facebook")}
              >
                <FaFacebookSquare /> Login with Facebook
              </button>
              <button
                className="social-book-icon"
                onClick={() => handleSocialLogin("google")}
              >
                <FcGoogle /> Login with Google
              </button>
              {/* <button className="social-book-icon" onClick={() => handleSocialLogin("github")}>
                Login with github
              </button> */}

              <div></div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {contextHolder}
    </div>
  );
};

export default SignupLogin;
